@use '@angular/material' as mat;
@import 'ngx-toastr/toastr';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


@include mat.core();

$dark-primary-text: rgba(black, 0.87);

$light-primary-text: white;

$mat-primary: (
50: #e0f4ff,
100: #b3e0ff, 
200: #80ccff, 
300: #4db8ff, 
400: #26a7ff, 
500: #03a4ed,  
600: #0083cc, 
700: #0072b3, 
800: #0061a0, 
900: #004080, 
A100: #80d7ff,
A200: #40c4ff,
A400: #00b0ff,
A700: #0091e6,
  contrast: (
    50: rgba(0, 0, 0, 0.87), /* Updated contrast color */
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: rgba(0, 0, 0, 0.87),
    500: white, /* Updated contrast color */
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(0, 0, 0, 0.87),
    A200: white,
    A400: white,
    A700: white
    )
  
);

$mat-accent: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

error-tooltip{
  color: red; font-size: 0.8em;
}
.successmsg{color: green; font-size: 0.8em;}
$primary: mat.define-palette($mat-primary, 500);

$accent: mat.define-palette($mat-accent, A200, A100, A400);

$theme: mat.define-light-theme((color: (primary: $primary,
        accent: $accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.all-component-themes($theme);

.mat-elevation-z7 {
  box-shadow: 0 4px 5px -2px #0003, 0 7px 10px 1px #00000024, 0 2px 16px 1px #0000001f !important;
}

.mat-elevation-z10 {
  box-shadow: 0 6px 6px -3px #0003, 0 10px 14px 1px #00000024, 0 4px 18px 3px #0000001f !important;
}


.spinner-container {
  position: fixed;
  height: 300px;
  width: 345px;
  display: flex;
  background: white;
  margin-top: 70px;
  z-index: 1;
  opacity: 0.5;
  justify-content: center;
}
form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-control {
    display: flex;
    width: 48%;
    margin-left: 5px;
    margin-right: 5px;

    mat-form-field {
      width: 90%;
    }

    mat-icon {
      padding-top: 16px;
      
    }
  }
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #03a4ed;
  --mdc-protected-button-label-text-color: #faf6f6;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(246, 241, 241, 0.1);
}

.errormsg{
  color:red;
  font-size: 0.8em;
  margin-top: 3px; 
  text-shadow: 0px 0px 0.15px red;
}
 .successmsg{
  color:rgb(31, 235, 68);
  font-size: 0.8em;
  margin-top: 3px;
  text-shadow: 0px 0px 0.15px rgb(79, 252, 31);
 }
 
 html .successsnackbar{
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(46, 235, 21, 0.87);
  --mat-snack-bar-button-color: #fdfcfd;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 37, 37, 0.87);
  --mat-snack-bar-button-color: #fdfbfc;
  --mdc-snackbar-supporting-font-weight:bold ;
}

/* Global styles for snackbar */
.errorsnackbar, .successsnackbar{
  // background-color: #F44336 !important; /* Red color for error */
  // color: white !important;
  margin-top: 90px !important;
  /* Custom positioning by moving to the right */
  text-align: center;
  border-radius: 5px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.snackbar-container-custom {
  position: fixed;
  right: 0;         /* Aligns snackbar to the right side */
  top: 50px;        /* Adds a margin from the top */
  z-index: 9999;    /* Ensures it is above other elements */
}